.user-dropdown {
  font-size: var(--font-size-md);
  &.user-dropdown-body-wrap {
    min-width: 200px;
  }
  .user-dropdown-header {
    padding: var(--spacing-md) var(--spacing-sm);
    font-weight: var(--font-semibold);
    background-color: var(--field-disabled-fill);
    border-radius: var(--default-radius) var(--default-radius) 0 0;
  }

  .user-dropdown-link {
    display: block;
    padding: var(--spacing-sm) var(--spacing-sm);
    text-decoration: none;
    &:last-child {
      border-radius: 0 0 var(--default-radius) var(--default-radius);
    }
    &:hover {
      color: var(--color-black);
      background-color: var(--color-primary-light);
    }
  }
}
