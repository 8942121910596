/* stylelint-disable selector-max-id */
.completeness-chart-label {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
  height: 32px;
  font-family: var(--font-family-open);
  font-size: var(--font-size-h2);
  font-weight: var(--font-semibold);
  &.completeness-chart-label-language {
    color: var(--color-primary-dark);
  }
  .completeness-chart-label-flag {
    width: 48px;
    min-width: 48px;
    height: 32px;
  }
  .completeness-chart-label-percentage {
    width: 68px;
    min-width: 68px;
    color: var(--color-black);
    text-align: right;
  }
}

#gradient-0 {
  stop[offset='0'] {
    stop-color: var(--color-orange500);
  }

  stop[offset='1'] {
    stop-color: var(--color-red500);
  }
}

#gradient-1 {
  stop[offset='0'] {
    stop-color: var(--color-amber300);
  }

  stop[offset='1'] {
    stop-color: var(--color-orange400);
  }
}

#gradient-2 {
  stop[offset='0'] {
    stop-color: var(--color-lime300);
  }

  stop[offset='1'] {
    stop-color: var(--color-amber300);
  }
}

#gradient-3 {
  stop[offset='0'] {
    stop-color: var(--color-green500);
  }

  stop[offset='1'] {
    stop-color: var(--color-lime300);
  }
}

.completeness-bar-0 {
  fill: url(#gradient-0);
}

.completeness-bar-1 {
  fill: url(#gradient-1);
}

.completeness-bar-2 {
  fill: url(#gradient-2);
}

.completeness-bar-3 {
  fill: url(#gradient-3);
}

.completeness-chart {
  &.highcharts-container {
    font-family: var(--font-family-open);
  }
  .highcharts-axis-title {
    font-size: var(--font-size-md);
    fill: var(--color-black);
  }
  .highcharts-axis-labels.highcharts-yaxis-labels {
    font-size: var(--font-size-h2);
    font-weight: var(--font-semibold);
  }
  .highcharts-breadcrumbs-separator text {
    font-size: var(--font-size-h2);
  }
  .highcharts-breadcrumbs-button {
    text {
      font-size: var(--font-size-h2);
      font-weight: var(--font-semibold);
      fill: var(--color-primary-dark);
    }
    &.highcharts-button-pressed {
      text {
        fill: var(--color-black);
      }
    }
  }
  .highcharts-breadcrumbs-group {
    :last-child text {
      fill: var(--color-black);
    }
  }
  .highcharts-drilldown-axis-label {
    text-decoration: none;
  }
  .highcharts-axis-line {
    stroke: none;
  }
  .highcharts-grid-line {
    stroke: none;
  }
}
