.breadcrumbs-divider {
  line-height: var(--line-height-xs);
  color: var(--foreground-on-primary);
}

.no-action-breadcrumb-button {
  button {
    cursor: default !important; /* stylelint-disable-line declaration-no-important */
  }
}
