body {
  background-color: var(--color-white);
  /* stylelint-disable selector-max-id */
  #application-container {
    min-height: 100dvh;
    overflow-y: auto;
  }
  #root {
    min-height: 100dvh;
  }
}
